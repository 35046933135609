<template>
  <main class="bg-white">
    <div class="relative red-gray-800">
      <div class="absolute inset-0">
        <img class="hidden lg:block object-cover object-bottom w-full h-full"
             src="../assets/lassen_header.webp" width="1280" height="675" alt="lassen">

        <img class="block lg:hidden object-cover object-bottom w-full h-full"
             src="../assets/lassen_header_small.webp" width="320" height="196" alt="lassen">

        <div class="absolute inset-0 bg-gray-800" style="mix-blend-mode: hard-light;" aria-hidden="true"></div>
      </div>
      <div class="relative py-24 px-4 mx-auto max-w-7xl sm:py-32 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-medium tracking-tight text-white sm:text-5xl lg:text-6xl truncate">Gecertificeerd RVS lassen, staal en aluminium
        </h1>
        <p class="mt-6 max-w-3xl text-base text-gray-300 ">
          Bij JC Las en Montagetechniek zijn we gespecialiseerd in het monteren en onderhouden van hoogwaardige metaalproducten. Metalen constructies, machines of onderdelen</p>
      </div>
    </div>

    <div class="overflow-hidden bg-white">
      <div class="relative py-16 px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="hidden absolute top-0 bottom-0 left-3/4 w-screen lg:block"></div>
        <div class="mx-auto max-w-prose text-base lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            <h2 class="mt-2 text-3xl font-bold tracking-tight leading-8 text-gray-900 uppercase sm:text-2xl ">TIG lassen van RVS, staal en aluminium</h2>
          </div>
        </div>
        <div class="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div class="relative lg:row-start-1 lg:col-start-2">
            <svg class="hidden absolute top-0 right-0 -mt-20 -mr-20 lg:block" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
              <defs>
                <pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
            </svg>
            <div class="relative pt-64 pb-10 rounded-xs overflow-hidden shadow-xs rounded-xs">
              <img class="absolute inset-0 h-full w-full object-cover"
                   src="../assets/lassen1.webp" alt="lassen">
              <div class="relative px-8">
                <section class="mt-8">
                  <div class="relative text-lg font-medium text-white md:flex-grow">
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div class="mt-8 lg:mt-0">
            <div class="mx-auto max-w-prose text-base lg:max-w-none">
              <p class="text-gray-600 text-md ">
                Bij JC Las en Montagetechniek zijn we gespecialiseerd in TIG lassen.
                TIG staat voor Tungsten Inert Gas: Tungsten is de Engelse naam voor wolfraam, het materiaal van de elektrode die je met TIG lassen gebruikt.
                Inert gas is het type gas dat je gebruikt bij deze techniek.<br><br>
              </p>
              <p class="text-gray-600 text-md ">
                Moet uw laswerk niet alleen stevig maar ook mooi zijn dan is TIG lassen de aangewezen techniek.
                TIG lassen is de moeilijkste maar ook de lastechniek met de hoogste kwaliteit en afwerking.
                Het wordt als moeilijkste lastechniek beschouwd omdat je twee handen nodig hebt wanneer je een lasstaaf gebuikt.
              </p>
            </div>
            <div class="mx-auto mt-5 text-gray-600 prose prose-indigo lg:max-w-none lg:row-start-1 lg:col-start-1">
              <p class="">
                TIG lassen is in de voedingsmiddelen industrie onmisbaar.
                De kwaliteit van een TIG las is heel hoog. Er ontstaan met TIG lassen geen raden in de gelaste leidingen.
                Zo hechten bacteriën en andere bederfelijke stoffen zich niet aan eventuele randen. Zo kunt u kwaliteit blijven leveren.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Tinybox
        v-model="index"
        :images="images"
        :loop="loopCheckbox"
        :no-thumbs="!thumbsCheckbox"
    ></Tinybox>

    <section class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-20">
      <ul role="list" class="grid grid-cols-2 md:grid-cols-4">
        <li v-for="(img, idx) in images" :key="idx" @click="index = idx" class="relative">
          <div class="block overflow-hidden w-full group aspect-w-10 aspect-h-7">
            <img :src="img.thumbnail" width="300" height="300" :alt="img.alt" class="object-cover pointer-events-none group-hover:opacity-75">
            <button type="button" class="absolute inset-0">
              <span class="sr-only">{{ img.caption }}</span>
            </button>
          </div>
        </li>
      </ul>
    </section>

    <div class="overflow-hidden py-16 px-4 bg-white xl:py-24 sm:px-6 lg:px-8">
      <div class="mx-auto max-w-max lg:max-w-7xl">
        <div class="relative z-10 mb-8 md:mb-2">
          <div class="max-w-prose text-base lg:max-w-none">
            <h2 class="mt-2 text-3xl font-bold tracking-tight leading-8 text-gray-900 uppercase sm:text-2xl ">Gecertificeerd RVS Lassen</h2>
          </div>
        </div>
        <div class="relative">
          <svg class="hidden absolute top-0 right-0 -mt-20 -mr-20 md:block" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
            <defs>
              <pattern id="95e8f2de-6d30-4b7e-8159-f791729db21b" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="384" fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)" />
          </svg>
          <svg class="hidden absolute bottom-0 left-0 -mb-20 -ml-20 md:block" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
            <defs>
              <pattern id="7a00fe67-0343-4a3c-8e81-c145097a3ce0" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="384" fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)" />
          </svg>
          <div class="relative md:bg-white md:py-6">
            <div class="lg:grid lg:grid-cols-2 lg:gap-6">
              <div class="text-gray-600 prose prose-indigo prose-md lg:max-w-none ">
                <p>RVS is gevoelig voor oververhitting, het is daarom belangrijk om bij RVS lassen het tempo hoog te houden. Als RVS op een te hoge temperatuur gelast wordt, kan het materiaal vervormen en kunnen er lelijke sporen optreden.</p>
                <p>Bij JC las en montagetechniek bent u verzekerd van een professioneel resultaat. Door onze specialisatie in het lassen van RVS vind u in onze afwerking geen vervorming of lelijke sporen.</p>

              </div>
              <div class="mt-6 text-gray-600 prose prose-indigo prose-md lg:mt-0 ">
                <p>De lasprocedure van RVS wordt altijd uitgevoerd onder bescherming van gas. Dit beschermgas zorgt er voor dat er geen zuurstof bij het smeltbad kan komen. Een smeltbad onstaat tijdens het lassen. Bij RVS duurt het langer voordat dit smeltbad uitgehard is.</p>
                <p>Het beschermingsgas zorgt er tijdens het uitharden voor dat er geen zuurstof bij het smeltbad kan. Zuurstof in het smeltbad zorgt namelijk voor oxidatie en dit tast de kwaliteit van de lasverbinding aan. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="overflow-hidden py-8 bg-white">
      <div class="px-4 mx-auto space-y-8 max-w-7xl sm:px-6 lg:px-8">
        <div class="mx-auto max-w-prose text-base lg:max-w-none">
          <h2 class="mt-2 text-3xl font-bold tracking-tight leading-8 text-gray-900 uppercase sm:text-2xl ">RVS TIG lassen</h2>
        </div>
        <div class="relative z-10 mx-auto max-w-prose text-base lg:max-w-5xl lg:mx-0 lg:pr-72">
          <p class="text-gray-600 text-md ">
            Bij JC las en montagetechniek leveren we hoogstaand TIG laswerk.
            Deze manier van lassen zorgt er voor dat zonder of met toevoeging van materiaal, twee delen aan elkaar smelten.
            Met TIG lassen kunnen hoeken en kanten perfect worden gelast en afgewerkt.
          </p>
        </div>
        <div class="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
          <div class="relative z-10">
            <div class="mx-auto text-gray-500 prose prose-indigo lg:max-w-none ">
              <p> Door ons te specialiseren in TIG lassen kunt u bij JC las en montagetechniek rekenen op hoogwaardige en duurzame laskwaliteit.
                Omdat deze manier van lassen een hoge vaardigheid vraagt van onze lassers, zorgen wij ervoor dat zij te allen tijde goed getraind zijn en het TIG lassen professioneel beheersen.
              </p>

              <h2 class="mt-2 text-3xl font-bold tracking-tight leading-8 text-gray-900 uppercase sm:text-2xl ">De voordelen van TIG lassen</h2>
              <ul class="">
                <li>Hoge laskwaliteit</li>
                <li>In alle posities toepasbaar</li>
                <li>Goede bescherming tegen oxidatie</li>
                <li>Geringe inbreng tegen warmte</li>
                <li>Geen vorming van slak</li>
              </ul>
            </div>
          </div>
          <div class="relative mx-auto mt-12 max-w-prose text-base lg:mt-0 lg:max-w-none">
            <svg class="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
              <defs>
                <pattern id="bedc54bc-7371-44a2-a2bc-dc68d819ae60" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)" />
            </svg>
            <section class="hidden lg:block relative bg-white rounded-sm shadow-lg">
              <div class="py-8 px-6 rounded-t-lg sm:px-10 sm:pt-10 sm:pb-8">
                <img class="w-9/12" src="../assets/metaalunie.svg" alt="Metaalunie" width="300" height="80">
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>

    <div class="relative py-12 bg-white sm:py-24 lg:py-6">
      <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-1 lg:gap-24 lg:items-start">
        <div class="relative px-4 max-w-md sm:max-w-3xl sm:px-6 lg:px-0">
          <!-- Content area -->
          <div class="pt-12 sm:pt-16 lg:pt-20">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900 uppercase sm:text-xl ">
              MIG/MAG lassen aluminium en RVS
            </h2>
            <div class="mt-6 space-y-6 text-gray-600">
              <p class="">
                MAG lassen staat voor Metal Active Gas lassen en is bij uitstek geschikt voor het lassen van RVS.
                MIG lassen wordt als dezelfde techniek gezien als MAG lassen, omdat het verschil alleen te vinden is in het gebruikte gas en draad tijdens het lassen.
                Het verschil in gas zorgt voor een verschil in lassnelheid.
              </p>
              <p class="">
                Door gassen op basis van argon gemengd met CO2 toe te voegen aan het lasproces, is MAG lassen zo geschikt voor RVS.
                Het toevoegen van deze gassen zorgt voor een hogere lassnelheid en voorkomt daardoor makkelijker oververhitting van het RVS.
                Daarnaast MAG lassen gemakkelijk toepasbaar in verschillende lasposities en geeft het uitstekende lasbescherming.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main>
</template>

<script>
import Tinybox from 'vue-tinybox';

export default {
  name : "Lassen",

  components: {
    Tinybox,
  },

  data() {
    return {
      images         : [
        {
          src       : '/assets/img/las1_img.webp',
          alt       : 'Image alttext',
          thumbnail : '/assets/img/las1_thumb.webp',
          caption   : ''
        },
        {
          src       : '/assets/img/las2_img.webp',
          alt       : 'Image alttext',
          thumbnail : '/assets/img/las2_thumb.webp',
          caption   : ''
        },
        {
          src       : '/assets/img/las3_img.webp',
          alt       : 'Image alttext',
          thumbnail : '/assets/img/las3_thumb.webp',
          caption   : ''
        },
        {
          src       : '/assets/img/las4_img.webp',
          alt       : 'Image alttext',
          thumbnail : '/assets/img/las4_thumb.webp',
          caption   : ''
        }
      ],
      index          : null,
      loopCheckbox   : false,
      thumbsCheckbox : true
    };
  },

  metaInfo : {
    title : 'Gecertifceerd RVS lassen',
    meta  : [
      {
        name    : 'description',
        content : 'Bij JC las en montagetechniek bent u verzekerd van een professioneel resultaat. Door onze specialisatie in het lassen van RVS vind u in onze afwerking geen vervorming of lelijke sporen.'
      }
    ]
  }
}
</script>

<style scoped>

</style>
